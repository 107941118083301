import { createRouter, createWebHistory } from 'vue-router'
import Home from '../Page/Home.vue'
import Choose from '../Page/Choose.vue'
import Answer from '../Page/Answer.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/choose',
    name: 'choose',
    component: Choose
  },
  {
    path: '/answer',
    name: 'answer',
    component: Answer
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../Page/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
