<template>
  <div v-cloak>
  <AppLayout id='cont' class="content home-background1">
    <div class="sec0" >
      <div class="wrapper-window" v-if="isWrapper" @click="closeInfo()"></div>
      <div id="apDiv12">
        <div id="apDiv105">{{intFace[0]}}</div>
        <div class="wrapper">
          <div id="apDiv3">{{intFace[1]}}</div>
          <div class="wrap85 cursor" @click="about(1)">
            <div
              id="apDiv85"
              class="zg5"
              data-title="{{intFace[2]}}"
            >
              <div id="apDiv95">
                <h4>{{intFace[3]}}</h4>
              </div>
              <div id="apDiv92">
                <!--<img src="img/str.png" width="22" height="23" alt=''>-->
                <img :src = "require('@/assets/img/str.png')" width="22" height="23" alt=''>
              </div>
            </div>
          </div>
        </div>
        <div class="toggleInfo">
          <div id="apDiv25" class="info">
            <div class="text-info" :class="{ 'text-info-de': lng === 'de' }">
              <u style="margin-bottom:10px">{{intFace[7]}}</u>
              {{intFace[8]}}
            </div>
            <div class="pos-close" @click="multiAsk()">x</div>
          </div>
          <div id='apDiv8'>
            <div
              id="apDiv107"
              class="info"
              @click="info()"
            > i
            </div>
            <h2 id="ndt1234">{{isCheckboxChecked1 ?  intFace[62] : intFace[4]}}</h2>
            <h5><textarea
              name="qv1" cols="25" id="qv1"
              :placeholder="isCheckboxChecked1 ? '' : intFace[59]"
              class="scroll"
              :class="{ 'bg-area': isCheckboxChecked1 }"
              v-model="textInput"
              :disabled="isCheckboxChecked1"
              :value="isCheckboxChecked1 ? intFace[60] : textInput"
            ></textarea>
            </h5>
            <div class="row-check">
              <div class="check1">
                <input type="checkbox" id="checkbox1" v-model="isCheckboxChecked1" @click="ponder">
                <label for="checkbox1" class="lab-check">{{intFace[63]}}</label>
              </div>
              <div class="check2">
                <input type="checkbox" id="checkbox" v-model="isCheckboxChecked">
                <label for="checkbox" class="lab-check">{{intFace[57]}}</label>
              </div>
            </div>
            <!--<div class="wr" v-if="isButtonDisabled"></div>-->
            <button id="apDiv2"
              :disabled="isButtonDisabled"
              @click="choose"
              :class="{ 'butt-disabl': isButtonDisabled }"
            >{{intFace[6]}}</button>
            <!--<div>-->
              <!--<div class="wr" v-if="isButtonDisabled1"></div>-->
              <!--<button id="apDiv2" @click="choose">{{intFace[6]}}</button>-->
            <!--</div>-->
          </div>
          <div id="apDiv23">
            <p id='vrn'
              style="margin:10px 0"
              :class="{ 'vrn-de': lng === 'de' }"
            >{{intFace[9]}}</p>
            <div class="grid-container">
              <div v-for="row in 3" :key="row" class="pos-hr">
                <div v-for="col in 2" :key="col" class="pos-vr-container">
                  <span class="pos-vr">{{ (row - 1) * 2 + col }}</span>
                  <input
                    :name="'ot' + ((row - 1) * 3 + col)"
                    type="text"
                    :id="'ot' + ((row - 1) * 3 + col)"
                    v-model="dataMultiAsk[(row - 1) * 3 + col - 1]"
                    size="6"
                    class="overflow"
                    @input="checkFields"
                    @change="handleFocus($event)"
                    @keyup.enter="handleFocus($event)"
                  >
                </div>
              </div>
            </div>
            <div id="apDiv78">
              <input type="submit" name="kn3" id="kn3" @click="multiAsk()" :value="intFace[55]" style="margin-right:10px">
              <input
                type="submit" name="kn1" id="kn1" @click="multiAskEnter(5)" :value="intFace[56]" style="margin-right:10px"
              >
              <input
                type="submit"
                name="kn2"
                id="kn2"
                @click="multiAskEnter(6)"
                :value="intFace[10]"
                :disabled="isDisabledMulti"
              >
            </div>
          </div>
        </div>
      </div>
      <div id="apDiv86">
        <div id="apDiv93">
          <h4><strong>{{intFace[11]}}</strong></h4>
          <p style="scrollbar-width: none;">{{intFace[14]}}
            <router-link to="/about">{{intFace[15]}}</router-link>
            {{intFace[16]}}
          </p>
          <h5>{{intFace[17]}}</h5>
        </div>
        <div id='apDiv81'>
          <div
            id="apDiv110"
            class="zg51 cursor"
            :data-title="intFace[12]"
            @click="about(2)"
          >
          </div>
          <div id="apDiv94">
            <a href="#"><img :src = "require('@/assets/img/str_v.png')" width="22" height="23" alt=''></a>
            <!--<img :src = "require('@/assets/img/str_v.png')" width="22" height="23" alt=''>-->
            <!--<a href="#"><img src="img/str_v.png" width="22" height="23" alt=''></a>-->
          </div>
          <div id="apDiv96">
            <h4>{{intFace[13]}}</h4>
          </div>
          <div id="apDiv88">
            <p>{{intFace[14]}}
              <router-link to="/about">{{intFace[15]}}</router-link>
              {{intFace[16]}}
            </p>
            <h5
              :class="{ 'inf-text-de': lng === 'de' }"
            >{{intFace[17]}}</h5>
          </div>
        </div>
        <div id="apDiv89">
          <div class="img"></div>
          <div class="wrapp"></div>
          <div id='plt'>
            <div id="lengv">{{esteb}}
              <select v-model="selectedLanguage" id="language-select" @change="changeLanguage">
                <option v-for="language in languages" :key="language.value" :value="language.value">
                  {{ language.text }}
                </option>
              </select>
            </div>
            <div id="apDiv131" style="display:block">
              <div id="wrap" class="wrap">
                <img id="mag" class='sol1' :src="url" alt=''>
                <div id="set" class="settings">
                  <div class="level">{{intFace[42]}}: {{levelAnswer}}</div>
                  <span class="title">{{intFace[43]}}<br> {{intFace[44]}}</span>
                  <p class="pos-value">{{truth}} </p>
                </div>
              </div>
              <div id="stamp">
                <img class='sol11' :src="urlStamp" alt=''>
                <!--<img class='sol11' :src="require('@/assets/img/stamp_yes_ru.jpg')" alt=''>-->
                <!--<img class='sol11' :src="require('@/assets/img/stamp_' + esteb + '_ru.jpg')" alt=''>-->
                <!--:src = "require('@/assets/img/ramk5.png')"-->
                <!--<img class='sol11' :src="urlStamp" alt=''>-->
                <div id="dial" v-if="isEsteblish"
                  class="dial"
                  :class="lng === 'de' ? 'sub-de' : lng === 'en' ? 'sub-en' : 'sub-ru'"
                >
                {{intFace[45]}}<br>{{intFace[46]}}
                </div>
                <div id="txt" v-else
                  class="dial-no"
                  :class="lng === 'ru' ? 'sub-no-ru' : lng === 'en' ? 'sub-no-en' : 'sub-no-de'"
                >
                  {{ nameHelper }} {{intFace[47]}}<br>{{intFace[48]}}
                </div>
              </div>
              <div id="apDiv149t" class="zg147"
               :class="lng === 'ru' ? 'pos-ru' : lng === 'en' ? 'pos-en' : 'pos-de'"
              >
               {{intFace[49]}} {{ nameHelper }}
                <br/>{{intFace[50]}}
              </div>
              <button
                id="buttEdit"
                @click="chooseMag(true, true)"
                v-if="numAsk >= 0"
                class="edit-helper"
              >&#9998;
                <span
                  class="tooltiptext"
                  :class="{ 'text-de': lng === 'de' }"
              >{{intFace[51]}}</span></button>
            
            </div>
            <!--src="img/ramk5.png"-->
            <img
              :src = "require('@/assets/img/ramk5.png')"
              class="fon-img"
              alt=""
              data-title="Закончить тренировку"
            >
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
  </div>
</template>

<script>
  import $ from 'jquery'
  import AppLayout from '../layout/AppLayout'
  // import urlStamp1 from '@/assets/img/stamp_yes_ru.jpg'
  import stampYesRu from '@/assets/img/stamp_yes_ru.jpg';
  
  export default {
    name: 'Home',
    components: {AppLayout},
    data() {
      return {
        textInput: '',
        selectedFruits: [],
        isCheckboxChecked: false,
        isCheckboxChecked1: false,
        questionWordsRu: ["кто", "кого", "кому", "когда", "кем", "что", "чего", "чему", "чем", "отчего",
          "какая", "какие", "какому", "какой", "какое", "какого", "каких", "каким", "каков", "какова",
          "каковы", "о каких", "о каком", "какую", "чей", "чья", "чьей", "чью", "о чьей", "чьи", "чьего",
          "чьему", "о чьем", "чьих", "чьим", "о чьих", "чьими", "который", "которая", "которые", "которого",
          "сколько", "скольких", "скольким", "сколькими", "где", "куда", "зачем", "откуда", "как", "почему",
          "о ком", "о чем", "каково", "каковой", "каковы", "отчего", "или"],
        questionWordsEn: ["who", "whom", "to whom", "when", "by whom", "what", "of which", "whose", "about which",
          "of", "about", "how many", "where", "where to", "wherefore", "why", "wherefrom", "how",
          "about whom", "about what", "whatsoever", "which", "which are", "of what", "from what", "what kind",
          "what time", "how much", "how long", "how often","how far","or"
        ],
        questionWordsDe: ["wer", "wen", "wem", "an wen", "wann", "von wem", "was", "welche", "deren",
          "über deren", "dessen", "über dessen", "wie viele", "wo", "wohin", "warum", "von wo",
          "wie", "über wen", "von was", "wessen", "wozu", "wie viel", "wie lange", "woher", "wie oft", "oder"
        ],
        selectedLanguage: '',
        languages: [
          { value: 'ru', text: 'ru' },
          { value: 'en', text: 'en' },
          { value: 'de', text: 'de' }
        ],
        dataMultiAsk: Array(9).fill(''),
        isButtonDisabled: true,
        isButtonDisabled1: false,
        isWrapper: false,
        isDisabledMulti: true,
        check1Txt: '',
        lng: '',
        intFace: [],
        interface: [],
        level: null,
        isMultiAsk: false,
        numAsk: null,
        nameHelper: '',
        url: '',
        setUrl: '',
        urlStamp: '',
        esteb: "",
        // esteb: "require('@/assets/img/stamp_yes_ru.jpg')",
        // urlStamp1: '',
        myMag: '',
        truth: null,
        clarity: null,
        isEsteblish: null,
        newImages1: [],
        images: ['/img/svitok_fon1.jpg'],
        levelAnswer: null,
        urlHelpers: [
          {url: 'mag1.gif', name: 'Angeline'},
          {url: 'mag2.gif', name: 'Archer'},
          {url: 'mag3.gif', name: 'Seraphin'},
          {url: 'mag5.gif', name: 'Gable'},
          {url: 'mag6.gif', name: 'Michelin'},
          {url: 'mag70.gif', name: 'Selaphin'},
          {url: 'mag8.gif', name: 'Uriah'},
          {url: 'mag10.gif', name: 'Jegudian'},
          {url: 'mag12.gif', name: 'Cherubin'},
          {url: 'mag14.gif', name: 'Seraphin'},
          {url: 'mag15.gif', name: 'Archer'},
          {url: 'mag17.gif', name: 'Raphel'},
          {url: 'mag18.gif', name: 'Selaphin'},
          {url: 'mag72.gif', name: 'Raphel'},
          {url: 'mag9.gif', name: 'Selaphin'},
          {url: 'mag19.gif', name: 'Gable'},
          // {url: 'mag11.gif', name: 'Angeline'},   //todo разм.
        ],
      }
    },
    created() {
      if (!localStorage.getItem('lng')) {
        const getBrowserLanguage = () => {
          const language = navigator.language || navigator.userLanguage
          return language.split('-')[0]
        }
        let lng = getBrowserLanguage()
        this.selectedLanguage = lng
        this.lg = (lng === 'ru') ? 1 : (lng === 'en') ? 2 : (lng === 'de') ? 3 : 0;
        import("../data/interface.js")
          .then(module => {
            this.interface = module.intface
            localStorage.setItem('interface', JSON.stringify(this.interface))
            this.changeLanguage()
          })
          .catch(err => {
            console.error('Ошибка при динамическом импорте:', err)
          })
      } else {
        this.lng = localStorage.getItem('lng');
        this.selectedLanguage = this.lng;
        this.intFace = JSON.parse(localStorage.getItem('intFace'))
      }

/*
      if (localStorage.getItem('numAsk')) {
        this.numAsk = +localStorage.getItem('numAsk')
      } else {
        localStorage.setItem('numAsk', this.numAsk)
      }
      if (localStorage.getItem('pageRebootHome') === 'true') {
        this.reboot()
      } else {
        localStorage.setItem('pageRebootHome', 'true')
        this.chooseMag(false, true)
      }
*/

      // this.preloadImage(estebl, this.lng);
      
      /*
            const img = new Image()
            // img.src = '/img/svitki_09.png'
            img.src = "require('@/assets/img/svitki_09.png')"
            img.onload = () => {
              localStorage.setItem('backgroundImage', '/img/svitki_09.png')
            }
      */
      this.asks()
    },
    async mounted() {
      // this.lng = localStorage.getItem('lng');
      // console.log('-->', this.lng)
      if (localStorage.getItem('numAsk')) {
        this.numAsk = +localStorage.getItem('numAsk')
      } else {
        localStorage.setItem('numAsk', this.numAsk)
      }
      if (localStorage.getItem('pageRebootHome') === 'true') {
        this.reboot()
      } else {
        localStorage.setItem('pageRebootHome', 'true')
        this.chooseMag(false, true)
      }

      // this.preloadImage();
    },
    computed: {
      isButtonDisabled() {
        return (!this.isCheckboxChecked1 && this.textInput.trim() === '') || this.isButtonDisabled1;
      }
    },
    watch: {
      isButtonDisabled() {
        this.isButtonDisabled = true
      },
      textInput(newVal) {
        this.checkText(newVal);
      }
    },
    methods: {
/*
      preloadImage() {
        this.esteb = stampYesRu;
      },
*/
      preloadImage(estebl, lng) {
        const imagePath = `@/assets/img/stamp_${estebl}_${lng}.jpg`;
        this.urlStamp = require(`${imagePath}`);
      },
/*
      ponder() {
        // this.isButtonDisabled = false
        // localStorage.setItem('check', this.isCheckboxChecked1)
        // console.log('::',this.textInput)
/!*
        if (this.isCheckboxChecked1) {
          this.check1Txt = 'VV'
        }
      
*!/
      },
*/
      asks() {
        this.questionWords = (this.lng === 'ru') ? this.questionWordsRu : (this.lng === 'en') ?
          this.questionWordsEn : this.questionWordsDe
/*
        console.log('>',this.intFace,this.intFace[63])
        this.check1Txt = this.intFace[63]
        // this.check1Txt = (this.lng === 'ru') ? this.intFace[63][1] : (this.lng === 'en') ?
        //   this.intFace[63][2] : this.intFace[63][3]
*/

      },
      checkText(text) {
        const words = text.toLowerCase().split(/\s+/);
        this.isCheckboxChecked = this.questionWords.some(word => words.includes(word.toLowerCase()));
      },
      handleFocus(event) {
        const input = event.target
        setTimeout(() => {
          input.setSelectionRange(0, 0)
          input.blur()
        }, 0)
      },
      checkFields() {
        if (this.dataMultiAsk.filter(item => item !== '').length > 1) {
          this.isDisabledMulti = false
          document.getElementById("kn2").style.opacity = '1'
          document.getElementById("kn2").style.cursor = 'pointer'
        } else {
          this.isDisabledMulti = true
          document.getElementById("kn2").style.opacity = '0.5'
          document.getElementById("kn2").style.cursor = 'auto'
        }
      },
      changeLanguage() {
        const bb =!!!localStorage.getItem('lng')
        const lng = this.selectedLanguage
        this.lng = lng
        this.asks()
        localStorage.setItem('lng', lng);
        this.lg = (lng === 'ru') ? 1 : (lng === 'en') ? 2 : (lng === 'de') ? 3 : 0;
        const data = JSON.parse(localStorage.getItem('interface'));
        const interface_ru = data.map(item => item[1]);
        const interface_en = data.map(item => item[2]);
        const interface_de = data.map(item => item[3]);
        localStorage.setItem('interface_ru', JSON.stringify(interface_ru));
        localStorage.setItem('interface_en', JSON.stringify(interface_en));
        localStorage.setItem('interface_de', JSON.stringify(interface_de));
        this.intFace = (lng === 'ru') ? JSON.parse(localStorage.getItem('interface_ru')) :
          (lng === 'en') ? JSON.parse(localStorage.getItem('interface_en')) :
            (lng === 'de') ? JSON.parse(localStorage.getItem('interface_de')) : [];
        localStorage.setItem('intFace', JSON.stringify(this.intFace))
        this.urlStamp = `img/stamp_yes_${this.lng}.jpg`
        // this.urlStamp = `require('@/assets/img/stamp_yes_${this.lng}.jpg')`
        // this.preloadImage(estebl, this.lng);

        console.log('oo',bb)
        if (bb) {
          this.chooseMag(true, false)
        }
      },
      async chooseMag(isEdit, mm) {
        this.isButtonDisabled1 = true
        let estebl = Math.random() < 0.5 ? 'yes' : 'no'
        // this.esteb = estebl
        // this.esteb ="require('@/assets/img/stamp_yes_ru.jpg')"
        // console.log('esteb->',this.esteb)
        let n = 0
        let myMag = localStorage.getItem('myMag')
        console.log('><',!myMag, isEdit, !myMag || isEdit)
        if (!myMag || isEdit) {
          while (n < 3) {
            this.isEsteblish = estebl === 'yes'
            // console.log('this.urlStamp->',`require('@/assets/img/stamp_yes_${this.lng}.jpg')`)
            // this.urlStamp = `require('@/assets/img/stamp_${estebl}_${this.lng}.jpg')`
            // this.urlStamp = `require('@/assets/img/stamp_yes_${this.lng}.jpg`
            this.urlStamp = `img/stamp_${estebl}_${this.lng}.jpg`
            
/*
            console.log('!!', estebl, this.lng)
            this.preloadImage(estebl, this.lng);
*/
            if (mm) this.changeHelper()
            this.loader('#txt', 10, 500)
            this.level = this.getRandomNumber(1, 4)
            if (this.level === 2 || this.level === 3) this.level = Math.random() < 0.5 ? 2 : 3
            this.levelAnswer = this.getLevel(this.level)
            await this.sleep(3800)
            // alert(1)
            if (estebl === 'yes') {
              // $("#apDiv2").css({'cursor': 'pointer', 'opacity': 1})
              // console.log('!!',this.isButtonDisabled, this.textInput, this.textInput.trim() !== '' )
              // if (this.textInput.trim() !== '')
              // this.isButtonDisabled = true //false
              localStorage.setItem('myMag', this.url)
              localStorage.setItem('name', this.nameHelper)
              localStorage.setItem('level', this.level)
              this.isButtonDisabled1 = false
              return
            } else {
              this.isButtonDisabled1 = true
              await this.sleep(1600)
              // if (this.textInput.trim() !== '')  {
              //   $("#apDiv2").css({'cursor': 'pointer', 'opacity': 0.7})
              // }
            }
            estebl = Math.random() < 0.5 ? 'yes' : 'no'
            // this.esteb = estebl
            if (n === 1) estebl = 'yes'
            n++
          }
          // this.isButtonDisabled1 = false //true
        }
      },
      async reboot() {
        // $("#apDiv2").css({'cursor': 'pointer', 'opacity': 1})
        // if (this.textInput.trim() !== '') this.isButtonDisabled = false
        $("#plt").hide()
        $("#cont").hide()
        const imgURL1 = localStorage.getItem('myMag')
        // const imgURL = localStorage.getItem('backgroundImage')
        // const img = new Image()
        const img1 = new Image()
        // img.src = imgURL
        img1.src = imgURL1
        // await img.decode()
        await img1.decode()
        $('#apDiv8').css('background', '/img/images3.jpg').show()
        $("#plt").show()
        $("#cont").show()
        let level = localStorage.getItem('level')
        let url = localStorage.getItem('myMag')
        this.nameHelper = localStorage.getItem('name')
        this.urlStamp = `img/stamp_yes_${this.lng}.jpg`
        this.isEsteblish = true
        this.levelAnswer = this.getLevel(+level)
        this.url = url
        $("#mag").css({'width': '120px', 'height': '109px', 'left': '2px', 'top': '8px'})
        $("#wrap").css({'background': '#e4c8a2'})
      },
      preloadImages(imageUrls) {
        return Promise.all(
          imageUrls.map((url) => {
            return new Promise((resolve, reject) => {
              const img = new Image()
              img.src = url
              img.onload = resolve
              img.onerror = reject
            })
          }),
        )
      },
      getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
      },
      loader(selector, times, speed) {
        let count = 0
        function blinker() {
          if (count < times) {
            $(selector).fadeOut(speed, function () {
              $(selector).fadeIn(speed)
              count++
              blinker()
            })
          }
        }
        blinker()
      },
      getLevel(lev) {
        if (lev === 1) {
          this.truth = '> 60%'
          return "I"
        } else if (lev === 2) {
          this.truth = '> 65%'
          return "II"
        } else if (lev === 3) {
          this.truth = '> 65%'
          return "II"
        } else if (lev === 4) {
          this.truth = '> 70%'
          return "III"
        } else if (lev === 5) {
          this.truth = '> 75%'
          return "IV"
        }
      },
      changeHelper() {
        $('#buttEdit').hide()
        let url = this.urlHelpers[Math.floor(Math.random() * this.urlHelpers.length)]
        this.$store.dispatch('loadUrl', url.url)
        this.setUrl = url
        this.nameHelper = url.name
        this.truth = ''
        this.clarity = Math.floor(Math.random() * 51) + 30
        this.numAsk = 0
        localStorage.setItem('numAsk', 0)
        this.cardHelper(url.url, url.name)
      },
      async cardHelper(name, nameHelper) {
        // this.url = `./assets/img/${name}`
        this.url = require('@/assets/img/' + name)
        // this.url = `img/${name}`
        document.getElementById('apDiv149t').style.opacity = '0'
        document.getElementById('stamp').style.opacity = '0'
        document.getElementById('set').style.opacity = '0'
        document.getElementById('wrap').style.background = 'transparent'
        let $div = $("#mag")
        let height = 243 //$div.height();
        let width = 316 //$div.width();
        const k = window.innerWidth > 960 ? 40 : 80
        $div.css({
          'height': 0, 'width': 0, 'margin-top': height / 2, 'margin-bottom': height / 2,
          'margin-left': width / 2 - k, 'margin-right': width / 2, 'padding': 0,
        }).animate({
          'height': height - k, 'width': width - k, 'margin-top': 0,
          'margin-bottom': 0, 'margin-left': "20px", 'margin-right': 0,
        }, 1600)

        $div.animate({
          'width': '120px', 'height': '109px', 'opacity': 1,
          'margin-top': $div.height() / 2, 'margin-left': $div.width() / 2,
        }, 1000)
        $div.css({'left': '2px', 'top': '8px'})
        await this.sleep(2800)
        document.getElementById('wrap').style.background = '#e4c8a2'
        document.getElementById('apDiv149t').style.opacity = '1'
        this.level = this.getRandomNumber(1, 4)
        this.levelAnswer = this.getLevel(this.level)
        $('#buttEdit').hide()
        $('.level').hide()
        $('.pos-vaue').hide()
        document.getElementById('set').style.opacity = '1'
        $('.level').show()
        $('.pos-vaue').show()
        $('#buttEdit').show()
        await this.sleep(1000)
        document.getElementById('stamp').style.opacity = '1'
        await this.sleep(1000)
        if (this.isEsteblish) document.getElementById('dial').style.opacity = '1'
        await this.sleep(200)
        localStorage.setItem('myMag', this.url)
        localStorage.setItem('name', this.nameHelper)
        localStorage.setItem('level', this.level)
        // this.isButtonDisabled1 = false
      },
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
      },
      async about(num) {
        if (num === 1) {
          document.getElementById('apDiv8').style.display = 'none'
          if (document.getElementById('apDiv23').style.display = 'block') {
            document.getElementById('apDiv23').style.display = 'none'
          }
          document.getElementById('apDiv85').style.display = 'none'
          document.getElementById('apDiv93').style.display = 'none'
          document.getElementById('apDiv3').style.display = 'none'
          $('#apDiv81').animate({height: "show"}, 600)
        } else {
          $('#apDiv81').animate({height: "hide"}, 600)

          function func() {
            document.getElementById("apDiv86").style.opacity = '0'
            $('#apDiv85').show(0)
            $("#apDiv86").animate({opacity: "1"}, 0)
            document.getElementById('apDiv8').style.display = 'block'
          }

          setTimeout(func, 600)
          await this.sleep(600)
          document.getElementById('apDiv3').style.display = 'block'
          document.getElementById('apDiv2').style.display = 'block'
        }
      },
      info() {
        this.isWrapper = true
        document.getElementById("apDiv25").style.display = 'block'
        document.getElementById("apDiv2").style.display = 'none'
        document.getElementById("apDiv8").style.display = 'none'
      },
      closeInfo() {
        this.isWrapper = false
        document.getElementById("apDiv25").style.display = 'none'
        document.getElementById("apDiv23").style.display = 'none'
        document.getElementById("apDiv2").style.display = 'block'
        document.getElementById("apDiv8").style.display = 'block'
      },
      async choose() { //todo
        if (this.isButtonDisabled) {
          const text = document.getElementById('qv1').value
          localStorage.setItem('check', this.isCheckboxChecked1)
          if (this.isCheckboxChecked1) {
            localStorage.setItem('ask', this.intFace[64])
          } else {
            localStorage.setItem('ask', `${this.intFace[41]}:  ${text}?`)
          }
/*
          let questionWords = ["кто", "кого", "кому", "когда", "кем", "что", "чего", "чему", "чем", "отчего",
            "какая", "какие", "какому", "какой", "какое", "какого", "каких", "каким", "каков", "какова",
            "каковы", "о каких", "о каком", "какую", "чей", "чья", "чьей", "чью", "о чьей", "чьи", "чьего",
            "чьему", "о чьем", "чьих", "чьим", "о чьих", "чьими", "который", "которая", "которые", "которого",
            "сколько", "скольких", "скольким", "сколькими", "где", "куда", "зачем", "откуда", "как", "почему",
            "о ком", "о чем", "каково", "каковой", "каковы", "отчего", "или"]
*/
          let lowerCaseText = text.toLowerCase()
          let hasQuestionWord = this.questionWords.some(word => lowerCaseText.includes(word))
          this.$store.dispatch('loadText', text)
          if (hasQuestionWord || this.isCheckboxChecked) {
/*
            for (let i = 1; i < 7; i++) {
              // document.getElementById("ot" + i).disabled = false
            }
*/
            this.checkFields()
            document.getElementById("apDiv23").style.display = 'block'
            // document.getElementById("apDiv25").style.display = 'none'
            document.getElementById("apDiv2").style.display = 'none'
            document.getElementById("apDiv8").style.display = 'none'
            // this.isWrapper = true
            this.$store.dispatch('showMulti', true)
            localStorage.setItem('isShowMulti', true)
          } else {
            this.isMultiAsk = false
            this.$store.dispatch('showMulti', false)
            localStorage.setItem('isShowMulti', false)
            // document.body.style.background = 'url("img/101.png")'
            await this.sleep(100)
            this.$router.push({path: '/choose'})
          }
        }
      },
      multiAsk() {
        this.isWrapper = false
        document.getElementById("apDiv25").style.display = 'none'
        document.getElementById("apDiv23").style.display = 'none'
        document.getElementById("apDiv2").style.display = 'block'
        document.getElementById("apDiv8").style.display = 'block'
      },
      multiAskEnter(n2) {
        if (n2 === 5) {
          document.getElementById("ot1").value = ''
          document.getElementById("ot2").value = ''
          document.getElementById("ot3").value = ''
          document.getElementById("ot4").value = ''
          document.getElementById("ot5").value = ''
          document.getElementById("ot6").value = ''
          // document.getElementById("ot7").value = ''
          // document.getElementById("ot8").value = ''
          // document.getElementById("ot9").value = ''
        }
        if (n2 === 6) {
          this.dataMultiAsk = this.dataMultiAsk.filter(item => item !== "")
          this.$store.dispatch('dataMultiAsk', this.dataMultiAsk)
          localStorage.setItem('dataMultiAsk', JSON.stringify(this.dataMultiAsk))
          document.getElementById("apDiv23").style.display = 'none'
          document.getElementById("apDiv8").style.display = 'block'
          this.$store.dispatch('showMulti', true)
          this.isWrapper = false
          this.$router.push({path: '/choose'})
        }
      },
/*
      preloadImage(n, url, ext) {
        const img = new Image()
        img.src = `${url}.${ext}`
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          const imgData = canvas.toDataURL(`image/${ext}`)
          localStorage.setItem(`backgroundImage${n}`, imgData)
        }
      },
*/
/*
      preloadImage(n, imgSrc, ext) {
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const imgData = canvas.toDataURL(`image/${ext}`);
          localStorage.setItem(`backgroundImage${n}`, imgData);
        };
      },
      loadImage(k, n, url, ext) {
        const imgData = localStorage.getItem(`backgroundImage${n}`)
        if (imgData) {
          if (k === 0) {
            document.body.style.backgroundImage = `url(${imgData})`
          } else {
            document.getElementById("apDiv8").style.backgroundImage = '../img/images3.jpg'
          }
        } else {
          if (k === 0) {
            document.body.style.backgroundImage = `${url}.${ext}`
          } else {
            document.getElementById("apDiv8").style.backgroundImage = '../img/images3.jpg'
          }
          this.preloadImage(n, url, ext)
        }
      },
*/
    },
  }
</script>

<style scoped>
  .pos-vr{
    margin:0 7px;
  }
  .scroll{
    scrollbar-width:none;
  }
/*
  .overflow{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
*/
  .settings{
    position:relative;
    font-size:10px;
    width:94px;
    line-height:1.7;
    text-align:start;
    padding-top:4px;
    padding-left:7px;
  }
  .level{
    font-weight:bold;
    font-size:14px;
    text-align:center;
    text-decoration:underline;
    position:relative;
    top:-4px;
  }
/*
  .grid-container{
    display:flex;
    flex-wrap:wrap;
    width:180px;
  }
*/
/*
  .pos-hr{
    display:flex;
    width:100%;
  }
*/
/*
  .pos-vr-container{
    display:flex;
    flex-basis:33.33%;
    box-sizing:border-box;
    padding:5px;
    width:55%;
  }
  .pos-vr-container {
    display: flex;
    flex-basis: 50%;
    box-sizing: border-box;
    padding: 5px;
  }
*/
  
  .text-de {
    left: -61px;
    top: -50px;
    width: 109px;
  }
  .vrn-de {
    font-size:15px !important;
  }
  #vrn{
    margin-bottom:14px;
  }
  .pos-value{
    font-size:12px;
    position:relative;
    top:45px;
    text-align:center;
    font-weight:bold;
  }
  #lengv {
    position: absolute;
    top: 15px;
    right: 17px;
  }
  .wr {
    background: transparent;
    position: absolute;
    height: 50px;
    width: 254px;
    left: 0;
    top: 138px;
    z-index: 100;
  }
/*
  [v-cloak] {
    !*display: none !important;*!
    background:#000 !important;
  }
*/

</style>

